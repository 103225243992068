<template>

  <div>

    <auth-input
      :hideable="false"
      :label="'Code'"
      :placeholder="'Paste Code'"
      :type="'text'"
      :initial="''"
      v-on:input="onInput($event)"
    ></auth-input>

  </div>

</template>

<script>
import AuthInput from './AuthInput'

export default {
  components: { AuthInput },

  methods: {
    /**
     * Test input validity
     */
    isValid (value) {
      return true
    },

    /**
     * Handle the input event
     */
    onInput (e) {
      this.$emit('input', e)
      this.$emit('valid', this.isValid(e))
    }
  }
}
</script>

<style lang="sass" scoped>

p.message
  color: #FF3223
  background: transparent
  height: 0.4rem
  line-height: 1rem
  margin-bottom: 0.2rem

  & > span::before
    content: ''
    width: 16px
    height: 8px
    margin-left: 0.2rem
    display: inline-block
    background: url(/static/images/auth_error.svg) no-repeat left bottom

</style>
