<template>
  <div class="form-block">

    <div class="form-block__wrapper">
      <!-- Back to Forgot Password Screen -->
      <go-back
        :view="this.$t('myaccount.emails.forgot-password')"
        :text="this.$t('myaccount.emails.re-enter')"
      ></go-back>

      <span
        class="is-pulled-left has-text-left instruction"
      >
        Check your email and and paste the verification code below:
      </span>

      <!-- The Credentials Form -->
      <form @submit.prevent="handleSubmit">

        <!-- Reset Code Field -->
        <reset-code-field
          v-on:input="codeValue = $event"
          v-on:valid="validCode = $event"
        ></reset-code-field>

        <!-- Reset Code Validation -->
        <p
          class="
            message
            is-size-7
            has-text-left
            has-text-weight-medium
          "
        >
          <span
            :class="(!codeValue || validCode) && 'is-invisible'"
          >
            Incorrect code
          </span>

          <a
            class="is-pulled-right"
            @click="resendCode"
          >Re-send code</a>
        </p>

        <span class="is-pulled-left has-text-left instruction">
          Reset your password:
        </span>

        <!-- Reset Password Fields: Validation UI & Confirm Value -->
        <password-field
          :is-confirmed="true"
          :is-validated="true"
          v-on:input="matchValue = $event"
          v-on:match="validMatch = $event"
          v-on:valid="validPassword = $event"
        ></password-field>

        <!-- Password Confirm Validation -->
        <p
          class="
            message
            is-size-7
            has-text-left
            has-text-weight-medium
          "
          :class="(!matchValue || validMatch) && 'is-invisible'"
        >
          <span>Passwords don't match</span>
        </p>

        <!-- Form Submit Button -->
        <button
          class="
            button
            is-medium
            has-background-grey-lighter
            is-fullwidth
            has-text-weight-semibold
            has-text-white
          "
          :class="validState() && 'valid'"
          :disabled="!validState()"
        >
          <span class="is-size-6">
            Confirm
          </span>
        </button>

      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import GoBack from './components/GoBack'
import PasswordField from './components/PasswordField'
import ResetCodeField from './components/ResetCodeField'

export default {
  components: {
    GoBack,
    PasswordField,
    ResetCodeField
  },

  props: {
    email: String
  },

  data: () => ({
    // Reset code validation
    codeValue: '',
    validCode: false,

    // Match password validation
    matchValue: '',
    validMatch: false,

    // New password validation
    validPassword: false,

    // Form was submitted and failed
    submissionError: false
  }),

  methods: {
    ...mapActions('account/', ['verification', 'confirmation']),

    /**
     * On valid state confirm new password and redirect to login.
     */
    async handleSubmit () {
      if (!this.validState()) return
      await this.confirmation({
        email: this.email,
        code: this.codeValue,
        password: this.matchValue
      })
        .then(() => {
          this.$messages.removeShown('auth')
          return this.$router.push({
            name: 'login'
          }, () => {
            this.$messages.success('Your password has been changed', 'auth')
            this.$emit('has-notification', true)
          })
        })
        .catch((error) => this.handleErrorMessage(error))
    },

    /**
     * Dispatch the verification code email again.
     */
    async resendCode () {
      this.$messages.removeShown('auth')
      await this.verification({
        email: atob(this.email)
      })
        .then(() => atob(this.email)) // Prevents errors if atob return an exception.
        .then(() => {
          this.$messages.success(
            this.$t('myaccount.notification-bar.code-resent'),
            'auth'
          )
          this.$emit('has-notification', true)
        })
        .catch((error) => this.handleErrorMessage(error))
    },

    /**
     * Generate the valid form state.
     */
    validState () {
      return this.validCode &&
        this.validPassword &&
        this.validMatch
    },

    /**
     * Handle AWS Cognito errors.
     * @error object
     */
    handleErrorMessage (error) {
      // Handle Code mismatch error.
      if (error.code === 'CodeMismatchException') {
        return this.validCode = false
      }
      // For all others errors.
      return this.sendErrorMessage('myaccount.notification-bar.error-contact-us')
    },

    /**
     * @message string
     */
    sendErrorMessage (message) {
      this.$router.push({
        name: 'login'
      }, () => {
        this.$messages.danger(this.$t(message), 'auth', false)
        this.$emit('has-notification', true)
      })
    }
  }
}
</script>

<style lang="sass" scoped>
.form-block
  padding: 10px 0
  &__wrapper
    margin: 62px auto 100px auto
    max-width: 464px
    @media (min-width: 768px)
      background-color: white
      padding: 32px 72px
  &__heading
    text-align: center
    margin-bottom: 24px
  &__desc
    text-align: center

span.instruction
  margin: 1rem 0 0.4rem 0
  font-size: 0.9rem

p.message
  color: #FF3223
  background: transparent
  height: 0.2rem
  line-height: 0.8rem
  margin-bottom: 0.2rem

  & > span::before
    content: ''
    width: 16px
    height: 8px
    margin-left: 0.2rem
    display: inline-block
    background: url(/static/images/auth_error.svg) no-repeat left bottom

form > button
    margin: 1rem 0
    border-radius: 4px

    &.valid
      background: #FF6441 !important

      &:hover
        background: #FF785A !important

</style>
