<template>

  <span
    class="
      has-text-weight-black
      is-pulled-left
    "
    @click="handleClick(view)"
  >
    <a class="has-text-primary">
      {{ text }}
    </a>
  </span>

</template>

<script>
export default {
  props: {
    view: String,
    text: String
  },
  methods: {
    /**
     * Route user to specific view or
     * redirect to preview's one.
     * @view string
     */
    handleClick (view) {
      if (view) {
        return this.$router.push({ name: view })
      }
      return this.$router.go(-1)
    }
  }
}
</script>

<style lang="sass" scoped>
span
  margin: 0.3rem 0
  font-size: 0.9rem

  & a::before
    content: ''
    width: 16px
    height: 16px
    display: inline-block
    background: url(/static/images/left_arrow.svg) no-repeat left bottom
</style>
